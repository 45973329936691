{
  "welcome": "Welcome in EN",
  "bannerCTA": "More than {total} diners seated, book your table today!",
  "firstReview": "First {review} people review, Get {point} hungry vouchers!",
  "lastReservationCTA": "Trending! Last booking was made {time}",
  "newRestaurantCTA": "New! Book & review to earn {point} hungry vouchers!",
  "totalRestaurantBookingCTA": "Popular! Booked by {total} people",
  "viewOtherBranch": "View other branches",
  "acceptVoucher": "Accept Voucher",
  "netPerson": "NET/person",
  "netSet": "NET/set",
  "subscribeNewsLetter": "I want to receive {amount} promo code, offers, feature updates, and promotional messages from Hungry Hub",
  "alreadyMember": "Already Member",
  "registerTermCondition": "By signing in or creating an account, you agree with our <a class='text-blue-500 hover:underline' href='{tncLink}' target='_blank'>General Terms & Conditions</a>  and <a class='text-blue-500 hover:underline' href='{privacyPolicyLink}' target='_blank'>Privacy Policy</a>",
  "dineIn": "dine in",
  "delivery": "delivery",
  "experience": "experience",
  "freeDelivery": "Free Delivery",
  "voucher": "Voucher",
  "forBusiness": "For Business",
  "giftCard": "Gift Card",
  "blog": "Blog",
  "downloadApp": "Download App",
  "help": "Help",
  "allowCookie": "To perform better and correctly please allow to accept cookies in your browser settings",
  "noInternet": "Please check your internet connection",
  "language": "Language",
  "signIn": "Sign In",
  "signInRegister": "Sign In / Register",
  "next": "Next",
  "totalAmount": "total amount",
  "pleaseEnterPhone": "Please enter your phone no.",
  "pleaseEnterEmail": "Please enter your email address",
  "shopeePay": "SHOPEE PAY",
  "debitCredit": "DEBIT / CREDIT CARD",
  "qrPayment": "QR Code",
  "shopeePayPaymentText": "The system will redirect to Shopee Pay application or website",
  "trueWalletPaymentText": "The system will redirect to True Wallet payment page",
  "weWillGenerateAmountPayment": "We will generate QR code for the amount {amount} Baht.",
  "useYourBank": "Use your Bank App to scan the QR code",
  "pleaseWait": "Please Wait",
  "search": "Search...",
  "applyFilter": "Apply",
  "priceRange": "Price Range",
  "startingPrice": "Starting Price",
  "highestPrice": "Highest Price",
  "showResult": "Show ({total} Result)",
  "showResults": "Show ({total} Results)",
  "whatsYourBudget": "What's your budget?",
  "underPrice": "Under {amount}",
  "pricePerPerson": "Price Per Person",
  "pricePerPackage": "Price Per Package",
  "popularPlace": "Popular Place",
  "allLocation": "All Location",
  "shoppingMall": "Shopping Mall",
  "popularZone": "Popular Zone",
  "btsRoute": "BTS Route",
  "mrtRoute": "MRT Route",
  "seeMore": "See More",
  "seeLess": "See Less",
  "name": "Name<sup>*</sup>",
  "egHomeOffice": "e.g. Home, Office",
  "address": "Address<sup>*</sup>",
  "addressDetails": "Address Details",
  "egFloorUnit": "e.g. Floor, unit number, building",
  "noteToDriver": "Note to Driver",
  "contactName": "Contact Name",
  "contactNumber": "Contact Number",
  "setPrimary": "Set Primary",
  "save": "Save",
  "cancel": "Cancel",
  "delete": "Delete",
  "myAddress": "My Addresses",
  "addAddress": "Add Address",
  "editAddress": "Edit Address",
  "addNewAddress": "Add New Address",
  "confirmNewAddress": "Confirm New Address",
  "edit": "Edit",
  "paymentMethod": "Payment Methods",
  "addCreditDebitCard": "Add Credit/Debit Card",
  "creditCardType": "Credit Card Type",
  "cardNumber": "Card Number",
  "cardHolderName": "Card Holder's Name",
  "expiresOn": "Expires On",
  "securityCodeCVV": "Security Code (CVV)",
  "pickLocation": "Pick Location",
  "primary": "Primary",
  "currentLocation": "Current Location",
  "savedAddress": "Saved Address",
  "recentlyUsed": "Recently Used",
  "new": "New",
  "trending": "Trending",
  "popular": "Popular",
  "findARestaurant": "Find a Restaurant",
  "packageTypeSectionTitle": "Feast Like Never Before",
  "packageTypeSectionSubTitle": "Learn more about our revolutionary packages",
  "restaurant": "restaurant | restaurants",
  "outlet": "outlets",
  "register": "register",
  "signOut": "Sign out",
  "earnPoints": "Earn Points",
  "first5Reviews": "Bonus for first 5 reviews!",
  "bonus": "Bonus",
  "from": "From",
  "deliveryHelperText": "Free Delivery for the first {distance} km, {exceedDeliveryFee}/km after that.",
  "deliveryPricingTierText": "Get {distance} km FREE and {pricePerKM}/km after that",
  "noMinimumCharge": "No minimum charge",
  "spend": "Spend {value}",
  "freeDeliveryPromotion": "Free delivery promotion",
  "filterByPDT": "Filter by people, date, and time",
  "howManyPeople": "How Many People?",
  "bigGroup": "Big Group",
  "adult": "adult | adults",
  "kid": "kid | kids",
  "selectDate": "Select Date",
  "upTo90DaysInAdvance": "Up to 90 days in advance",
  "today": "Today",
  "daysOfWeek": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  "selectTime": "Select Time",
  "atLeast15MinsInAdvance": "At least 15 mins in advance",
  "total": "Total",
  "totalDeposit": "Total Deposit",
  "totalPrepayment": "Total Prepayment",
  "totalOrderAmount": "Total Order Amount",
  "totalPayAtRestaurant": "Total Pay At Restaurant",
  "personalInformation": "Personal Information",
  "joinOrSignIn": "Join/Sign In",
  "forFasterCheckout": "For faster checkout!",
  "toUseYourGiftCard": "to use your Gift Card",
  "offers": "Offers",
  "dontForgetToUseOurSpecialOffers": "Don’t forget to use our special offers!",
  "loginToSaveMore": "Log in to save more!",
  "enterPromoCodeHere": "Enter Promo Code here",
  "totalPoints": "{totalPoints} Points",
  "availableForDiscount": "available for discount",
  "availableForRedeem": "available for redeem",
  "hungryhubPointRedemption": "Hungry Points Redemption",
  "redeemPoints": "Redeem Points",
  "dontUsePoint": "Don't Use Point",
  "selectYourGiftCard": "Select your Gift Card",
  "summary": "Summary",
  "pointsRedeemed": "{point} Points Redeemed",
  "termAndConditions": "Terms & Conditions",
  "byClickingConfirmationBooking": "By clicking <q>Confirm Booking</q>, you agree to our",
  "submit": "submit",
  "reviewedPackages": "Reviewed Packages",
  "information": "Information",
  "youMightAlsoLike": "You Might Also Like",
  "howToUseVoucher": "How to use voucher",
  "buyAVoucher": "Buy a voucher",
  "recommendedStaycations": "Recommended Staycations",
  "staycation": "Staycation",
  "acceptOffersAndGiftCard": "Accept Offers & Gift Cards",
  "comeMorePayLess": "Come More Pay Less",
  "acceptGiftCard": "Accept Gift Cards",
  "titleBuyVoucher": "Buy Voucher",
  "mostLovedByUsers": "Most Loved By Users",
  "addressName": "Address Name",
  "location": "Location",
  "phone": "Phone",
  "removeAddress": "Remove Address",
  "setAsPrimary": "Set as Primary",
  "view": "View",
  "saved": "saved",
  "copy": "copy",
  "share": "share",
  "settings": "Settings",
  "version": "Version",
  "privacyPolicy": "Privacy Policy",
  "logOut": "Log Out",
  "discardChange": "Discard Change",
  "saveChange": "Save Change",
  "myPreferences": "My Preferences",
  "creditDebit": "Credit/Debit Card",
  "youDontHaveCC": "You don't have any credit card",
  "hungryPoint": "Hungry Point",
  "giftCardValue": "Gift Card Value",
  "inTotal": "in Total",
  "myVoucher": "My voucher",
  "booked": "booked",
  "outlets": "Outlets",
  "bookingId": "Booking ID",
  "rateYourExperience": "Rate Your Experience",
  "bookAgain": "Book Again",
  "giftCards": "Gift Cards",
  "reviews": "Reviews",
  "payment": "Payment",
  "reloadQRCode": "Reload QR Code",
  "tags": "Tags",
  "bloggerPost": "Blogger’s Post"
}
